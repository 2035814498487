import React, { useRef, useEffect, useContext, useState, useCallback } from 'react';
import { observer, inject } from 'mobx-react';
import { Calendar } from 'devextreme-react';
import moment from 'moment';
import './LaunchDatePage.css';
import NavButtons from '../components/common/NavButtons';

const LaunchDatePage = inject('clinicStore')(observer(({ clinicStore, clinicId }) => {
    const isMounted = useRef(true);
    const [ready, setReady] = useState(false);
    const [selectedDate, setSelectedDate] = useState();

    useEffect(() => {
        clinicStore.refresh(clinicId).then(data => {
            if (!clinicStore.data.launchDate)
                setSelectedDate(moment().add(1, 'd'));
            else {
                console.log('HERE!!!!!!!!!!!!!');
                setSelectedDate(clinicStore.data.launchDate);
            }
            setReady(true);
        });

        return () => {
            isMounted.current = false;
        }
    }, []);  // eslint-disable-line

    const handleCalendarValueChange = ({ value }) => {
        console.log('VALUE!!!!', value);
        clinicStore.updateLaunchDate({ clinicId: clinicId, launchDate: value })
        setSelectedDate(value);
    }

    const handleDisableDates = (dtComponent) => {
        const now = new Date();
        return (dtComponent.date <= now);
    }
    return (
        <div className='launch-date p-4 w-50' >
            <h3>Data Migration</h3><p></p>
            <div className='d-flex align-items-center justify-content-between mb-3'>
                <div>What is your current platform? <span className='text-info'><strong>{clinicStore.data.emrVendor}</strong></span></div>
            </div>
            <div style={{ maxWidth: '600px' }}>
                <Calendar
                    disabledDates={handleDisableDates}
                    value={selectedDate}
                    onValueChanged={handleCalendarValueChange}
                />
            </div>
            <div class='mt-2'>
                Launch Date: <span class='text-info'><strong>{moment(selectedDate).format('dddd MMMM DD, yyyy')}</strong></span>
            </div>
            <NavButtons currentRoute='/launch-date' />
        </div>
    );
}));

export default LaunchDatePage;