import axios from 'axios';
import queryString from 'query-string';
import * as rax from 'retry-axios';

import { ACCESS_KEY } from './constants/storageKeys';

import * as fn from './utilities/_functions';

const cleanObj = (obj) => {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        }
    }
    return obj;
}

const getConfig = (key, token) => {
    const accessKey = window.localStorage.getItem(ACCESS_KEY);
    let config = {
        baseURL: `${process.env.REACT_APP_APP_API_PROTOCOL}://${process.env.REACT_APP_APP_API_DOMAIN}`,
        timeout: process.env.REACT_APP_APP_API_TIMEOUT,
        headers: {}
    };
    
    if (accessKey) {
        config.headers[ACCESS_KEY] = accessKey;
    }

    return config;
};

const api = {
    get: (uri, cancelCallback) => {
        const client = axios.create(getConfig());
        
        const CancelToken = axios.CancelToken;

        client.defaults.raxConfig = { retry: 1, retryDelay: 300, instance: client, backoffType: 'exponential' };
        rax.attach(client);

        const request = new Promise((resolve, reject) => {
            client.get(uri, {
                cancelToken: fn.isFunction(cancelCallback) ? new CancelToken(
                    function executor(c) {
                        cancelCallback(c);
                    }) : null
            })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        reject(error);
                    }
                })
        });

        return request;
    },
    post: (uri, option, cancelCallback, key, token) => {
        const client = axios.create(getConfig(key, token));
        const CancelToken = axios.CancelToken;

        client.defaults.raxConfig = { retry: 1, retryDelay: 300, instance: client, backoffType: 'exponential' };
        rax.attach(client);

        const request = new Promise((resolve, reject) => {
            client.post(uri, cleanObj(option), {
                cancelToken: fn.isFunction(cancelCallback) ? new CancelToken(
                    function executor(c) {
                        cancelCallback(c);
                    }) : null
            })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        reject(error);
                    }
                })
        });

        return request;
    },
    put: (uri, option, cancelCallback, key, token) => {
        const client = axios.create(getConfig(key, token));
        const CancelToken = axios.CancelToken;

        client.defaults.raxConfig = { retry: 1, retryDelay: 300, instance: client, backoffType: 'exponential' };
        rax.attach(client);

        const request = new Promise((resolve, reject) => {
            client.put(uri, cleanObj(option), {
                cancelToken: fn.isFunction(cancelCallback) ? new CancelToken(
                    function executor(c) {
                        cancelCallback(c);
                    }) : null
            })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        reject(error);
                    }
                })
        });

        return request;
    },
    delete: (uri, option, cancelCallback) => {
        const client = axios.create(getConfig());
        const CancelToken = axios.CancelToken;
        let request;

        client.defaults.raxConfig = { retry: 1, retryDelay: 300, instance: client, backoffType: 'exponential' };
        rax.attach(client);

        if (option) {
            request = new Promise((resolve, reject) => {
                client.delete(uri, {
                    data: cleanObj(option),
                    cancelToken: fn.isFunction(cancelCallback) ? new CancelToken(
                        function executor(c) {
                            cancelCallback(c);
                        }) : null
                })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (!axios.isCancel(error)) {
                            reject(error);
                        }
                    })
            });
        } else {
            request = new Promise((resolve, reject) => {
                client.delete(uri, {
                    cancelToken: fn.isFunction(cancelCallback) ? new CancelToken(
                        function executor(c) {
                            cancelCallback(c);
                        }) : null
                })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (!axios.isCancel(error)) {
                            reject(error);
                        }
                    })
            });
        }

        return request;
    },
    file: (uri, cancelCallback) => {
        let config = getConfig();

        config.responseType = 'blob';
        config.timeout = 0;

        const client = axios.create(config);
        const CancelToken = axios.CancelToken;

        client.defaults.raxConfig = { retry: 1, retryDelay: 300, instance: client, backoffType: 'exponential' };
        rax.attach(client);

        const request = new Promise((resolve, reject) => {
            client.get(uri, {
                cancelToken: fn.isFunction(cancelCallback) ? new CancelToken(
                    function executor(c) {
                        cancelCallback(c);
                    }) : null
            })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        reject(error);
                    }
                })
        });

        return request;
    }
}

const Business = {
    get: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_BUSINESS_API_URI}`, cancelCallback);
    }
}

const Clinics = {
    update: (option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_CLINICS_API_URI}`, option, cancelCallback);
    },
    updatePlatform: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_CLINICS_API_URI}/platform`, option, cancelCallback);
    },
    updateLaunchDate: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_CLINICS_API_URI}/launch-date`, option, cancelCallback);
    },
    updateBackupUrls: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_CLINICS_API_URI}/backup-url`, option, cancelCallback);
    },
    authorizeRetrieval: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_CLINICS_API_URI}/authorize-retrieval`, option, cancelCallback);
    },
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_CLINICS_API_URI}?id=${id}`,  cancelCallback);
    }
}

const Services = {
    add: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_SERVICES_API_URI}`, option, cancelCallback);
    },
    update: (option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_SERVICES_API_URI}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_SERVICES_API_URI}/${id}`, cancelCallback);
    },
    getPublic: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_SERVICES_API_URI}/public?clinicId=${id}`,  cancelCallback);
    },
    getPrivate: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_SERVICES_API_URI}/private?clinicId=${id}`,  cancelCallback);
    }
}

const Users = {
    add: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_USERS_API_URI}`, option, cancelCallback);
    },
    update: (option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_USERS_API_URI}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_USERS_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_USERS_API_URI}`,  cancelCallback);
    }
}

const ApiGroup = {
    Business,
    Clinics,
    Users,
    Services
}

export default ApiGroup;

