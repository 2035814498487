import React, { } from 'react';

export default function NoMigrationInfo() {
    return <div>
        <p><strong>When can we start?</strong></p>
        <ul>
            <li>Launch window: 1-2 weeks </li>
            <li>Equipment integration (if compatible) can be scheduled after data migration.</li>
            <li>Includes
                <ul><li>Paper migration tool</li></ul>
            </li>
        </ul>
    </div>
}

    