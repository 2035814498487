import React from 'react';

function Footer() {
  return (
    <footer className="bg-light text-left small py-3 p-2">
      &copy; 2024 Genki Technology - All Rights Reserved.
    </footer>
  );
}

export default Footer;

