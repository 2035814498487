import React, { useRef, useEffect, useState } from 'react';
import { Form, GroupItem, SimpleItem, Item, Label, RequiredRule, EmailRule, PatternRule, ButtonItem } from 'devextreme-react/form';
import { observer, inject } from 'mobx-react';
import { Popup } from 'devextreme-react/popup';
import NavButtons from '../components/common/NavButtons';
import { CheckBox, LoadIndicator } from 'devextreme-react';
import './UploadPage.css';

const UploadPage = inject('clinicStore')(observer(({ clinicStore, clinicId }) => {
    const isMounted = useRef(true);
    const [ready, setReady] = useState(false);
    const [formData, setFormData] = useState(null);
    const formRef = useRef(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [consentGiven, setConsentGiven] = useState(false);

    useEffect(() => {
        clinicStore.refresh(clinicId).then(data => {
            setFormData({ ...clinicStore.data });
            setReady(true);
        });

        return () => {
            isMounted.current = false;
        }
    }, []);  // eslint-disable-line

    const handleAuthorizeConfirm = async () => {
        setModalVisible(false);
        await clinicStore.authorizeRetrieval(clinicId);
        await clinicStore.refresh();
        console.log('ok button clicked');
    };

    const handleAuthorizeCancel = () => {
        setModalVisible(false);
        console.log('Cancel button clicked');
    };

    const toggleConsent = () => {
        setConsentGiven(!consentGiven);
    };

    const openModal = () => {
        const result = formRef.current.instance.validate(); // Validate the form
        console.log(result);
        if (result.isValid) {
            setModalVisible(true);
            console.log('OK button clicked');
        } else {
            alert('Please provide the database backup URL');
        }
    };

    const handleFormDataChanged = async () => {
        const updatedFormData = { ...formData };
        setFormData(updatedFormData);
        await clinicStore.updateBackupUrls({ ...updatedFormData, clinicId });
    };

    return (
        <div className='p-4 w-50 upload-page' >
            <h3>Files Backup Information</h3><p></p>
            {
                ready ?
                    <>
                        <Form onFieldDataChanged={handleFormDataChanged} formData={formData} ref={formRef}
                        >
                            <GroupItem caption="Please provide the URL to download database backup">
                                <SimpleItem dataField='databaseBackupUrl' label={{ visible: false }}>
                                    <RequiredRule message="Database backup URL is required" />
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem caption="Please provide the URL to download attachments backup">
                                <SimpleItem dataField='attachmentsBackupUrl' label={{ visible: false }}>
                                </SimpleItem>
                            </GroupItem>
                        </Form>
                        {
                            clinicStore.data && clinicStore.data.retrievalAuthorizedDateUtc != null &&
                            <div className='text-primary mb-4'>
                                <strong>Our support team has been notified to start processing your upload. Once we have successfully downloaded and extracted data from your database backup, we will provide additional online forms for you to confirm the details of services and user information.</strong>
                            </div>
                        }
                        {
                            clinicStore.data && clinicStore.data.retrievalAuthorizedDateUtc == null ? // if client has already authorized, then "next" is just a normal next button, otherwise show popup warning
                                <NavButtons currentRoute='/upload' onCustomNext={openModal} /> :
                                <NavButtons currentRoute='/upload' />
                        }

                    </> : <LoadIndicator height={40} width={40} />
            }
            <Popup
                visible={modalVisible}
                onHiding={() => setModalVisible(false)}
                dragEnabled={true}
                hideOnOutsideClick={true}
                showCloseButton={true}
                title="Data Retrieval Authorization"
                width={500}
                height={260}
            >
                <div style={{ marginBottom: 20 }}>
                    <CheckBox
                        iconSize={20}
                        value={consentGiven}
                        onValueChanged={toggleConsent}
                        text="I authorize the download and extraction of data from my database backup for database migration purposes. I understand that my data will be handled with the strictest confidentiality and the highest security standards.                    "
                    />
                </div>
                <div className="d-flex flex-column justify-content-between">
                    <div className="d-flex justify-content-end mt-4">
                        <button className="btn btn-default" onClick={handleAuthorizeCancel} >Cancel</button>
                        <button className="btn btn-primary" disabled={!consentGiven} onClick={handleAuthorizeConfirm} >Confirm and Continue</button>
                    </div>
                </div>
            </Popup>
        </div>
    );
}));

export default UploadPage;