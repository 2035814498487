import React from 'react';
import moment, { isMoment } from 'moment';
import { toast } from 'react-toastify';
import shortid from 'shortid';
import { baseZIndex } from 'devextreme/ui/overlay';

import * as ErrorMessages from '../constants/errorMessages';

export const newId = (prefix) => {
    let id = `${`${shortid.generate()}${shortid.generate()}${shortid.generate()}${shortid.generate()}${shortid.generate()}`.substring(0, 16)}`;

    while (id.indexOf('_') >= 0 || id.indexOf('-') >= 0) {
        id = id.replace('_', shortid.generate().substring(0, 1));
        id = id.replace('-', shortid.generate().substring(0, 1));
    }

    id = `${prefix}${id}`;
    return id;
}

export const isNullOrUndefined = (obj) => {
    return obj === null || typeof (obj) === 'undefined';
}

export const isFunction = (obj) => {
    return obj && typeof (obj) === 'function';
}

export const toBoolean = (obj) => {
    return (obj === true || obj === 'true' || obj === 1 || obj === 'yes');
}

export const toFileColor = (file) => {
    if (file) {
        file = file.toLowerCase();

        if (file.startsWith('image/') || ['.jpg', '.gif', '.png', '.bmp', '.svg', '.tiff', '.webp'].some(f => file.endsWith(f))) {
            return '#4f5d73';
        }
        if (file.startsWith('audio/') || ['.wav', '.m4a', '.m4b', '.m4p', '.mp3', '.wma'].some(f => file.endsWith(f))) {
            return '#1c74b8';
        }
        if (file.startsWith('video/') || ['.mp4', '.mov', '.wmv', '.avi', '.mkv', '.webm'].some(f => file.endsWith(f))) {
            return '#e6582a';
        }
        if (file === 'application/pdf' || file.endsWith('.pdf')) {
            return '#b30b00';
        }
        if (file.indexOf('word') >= 0 || ['.doc', '.docx'].some(f => file.endsWith(f))) {
            return '#1650b4';
        }
        if (file.indexOf('spreadsheet') >= 0 || file.indexOf('excel') >= 0 || ['.xls', '.xlsx'].some(f => file.endsWith(f))) {
            return '#02723b';
        }
        if (file.indexOf('csv') >= 0) {
            return '#304a1b';
        }
        if (['application/x-tar', 'application/gzip', 'application/x-7z-compressed', 'application/x-rar-compressed', 'application/zip'].some(m => m === file) ||
            ['.tar', '.gz', '.gzip', '.7z', '.zip', '.rar'].some(m => m === file)) {
            return '#f5af14';
        }
        if (file.startsWith('text/') || file.endsWith('.txt')) {
            return '#495057';
        }
    }

    return '#495057';
}

export const toFileIcon = (file, className) => {
    const color = toFileColor(file);

    if (file) {
        file = file.toLowerCase();

        if (file.startsWith('image/') || ['.jpg', '.gif', '.png', '.bmp', '.svg', '.tiff', '.webp'].some(f => file.endsWith(f))) {
            return <i className={'fal fa-file-image' + (className ? ` ${className}` : '')} style={{ color: color }}></i>;
        }
        if (file.startsWith('audio/') || ['.wav', '.m4a', '.m4b', '.m4p', '.mp3', '.wma'].some(f => file.endsWith(f))) {
            return <i className={'fal fa-file-audio' + (className ? ` ${className}` : '')} style={{ color: color }}></i>;
        }
        if (file.startsWith('video/') || ['.mp4', '.mov', '.wmv', '.avi', '.mkv', '.webm'].some(f => file.endsWith(f))) {
            return <i className={'fal fa-file-video' + (className ? ` ${className}` : '')} style={{ color: color }}></i>;
        }
        if (file === 'application/pdf' || file.endsWith('.pdf')) {
            return <i className={'fal fa-file-pdf' + (className ? ` ${className}` : '')} style={{ color: color }}></i>;
        }
        if (file.indexOf('word') >= 0 || ['.doc', '.docx'].some(f => file.endsWith(f))) {
            return <i className={'fal fa-file-word' + (className ? ` ${className}` : '')} style={{ color: color }}></i>;
        }
        if (file.indexOf('spreadsheet') >= 0 || file.indexOf('excel') >= 0 || ['.xls', '.xlsx'].some(f => file.endsWith(f))) {
            return <i className={'fal fa-file-excel' + (className ? ` ${className}` : '')} style={{ color: color }}></i>;
        }
        if (file.indexOf('csv') >= 0) {
            return <i className={'fal fa-file-csv' + (className ? ` ${className}` : '')} style={{ color: color }}></i>;
        }
        if (['application/x-tar', 'application/gzip', 'application/x-7z-compressed', 'application/x-rar-compressed', 'application/zip'].some(m => m === file) ||
            ['.tar', '.gz', '.gzip', '.7z', '.zip', '.rar'].some(m => m === file)) {
            return <i className={'fal fa-file-archive' + (className ? ` ${className}` : '')} style={{ color: color }}></i>;
        }
        if (file.startsWith('text/') || file.endsWith('.txt')) {
            return <i className={'fal fa-file-alt' + (className ? ` ${className}` : '')} style={{ color: color }}></i>;
        }
    }

    return <i className={'fal fa-file' + (className ? ` ${className}` : '')} style={{ color: color }}></i>;
}

export const toPaymentIcon = (method, className) => {
    if (method) {
        switch (method.toLowerCase()) {
            case 'cash':
                return <i className={'fal fa-money-bill text-center' + (className ? ` ${className}` : '')} style={{ color: '#1baf5e', minWidth: '35px' }}></i>;
            case 'debit':
                return <i className={'fal fa-credit-card-front text-center' + (className ? ` ${className}` : '')} style={{ color: '#5aa1b3', minWidth: '35px' }}></i>;
            case 'etransfer':
                return <i className={'fal fa-comments-alt-dollar text-center' + (className ? ` ${className}` : '')} style={{ color: '#f7b329', minWidth: '35px' }}></i>;
            case 'cheque':
                return <i className={'fal fa-money-check-edit-alt text-center' + (className ? ` ${className}` : '')} style={{ color: '#f79a00', minWidth: '35px' }}></i>;
            case 'visa':
                return <i className={'fab fa-cc-visa text-center' + (className ? ` ${className}` : '')} style={{ color: '#0350a0', minWidth: '35px' }}></i>;
            case 'mastercard':
                return <i className={'fab fa-cc-mastercard text-center' + (className ? ` ${className}` : '')} style={{ color: '#000063', minWidth: '35px' }}></i>;
            case 'amex':
                return <i className={'fab fa-cc-amex text-center' + (className ? ` ${className}` : '')} style={{ color: '#2374b6', minWidth: '35px' }}></i>;
            case 'preauthorized':
                return <i className={'fab fa-retweet text-center' + (className ? ` ${className}` : '')} style={{ color: '#094c5e', minWidth: '35px' }}></i>;
            case 'privateinsurance':
                return <i className={'fal fa-envelope-open-dollar text-center' + (className ? ` ${className}` : '')} style={{ color: '#009e9c', minWidth: '35px' }}></i>;
            case 'directdeposit':
                return <i className={'fal fa-university text-center' + (className ? ` ${className}` : '')} style={{ color: '#0059b3', minWidth: '35px' }}></i>;
            case 'storecredit':
                return <i className={'fal fa-box-usd text-center' + (className ? ` ${className}` : '')} style={{ color: '#393668', minWidth: '35px' }}></i>;
            default:
                break;
        }
    }

    return <i className={'fal fa-question-square' + (className ? ` ${className}` : '')} style={{ color: '#f5af14', minWidth: '35px' }}></i>;
}

export const getMethodDescription = (method) => {
    if (method) {
        switch (method.toLowerCase()) {
            case 'cash':
                return 'Cash';
            case 'debit':
                return 'INTERAC';
            case 'etransfer':
                return 'e-Transfer';
            case 'cheque':
                return 'Cheque';
            case 'visa':
                return 'VISA';
            case 'mastercard':
                return 'Mastercard';
            case 'amex':
                return 'America Express';
            case 'preauthorized':
                return 'Pre-Authorized';
            case 'privateinsurance':
                return 'Private insurance';
            case 'directdeposit':
                return 'Direct deposit';
            case 'storecredit':
                return 'Store credit';
            default:
                break;
        }
    }

    return 'Unknown';
}

export const toPaymentDescription = (method, className) => {
    if (method) {
        switch (method.toLowerCase()) {
            case 'cash':
                return <span className={(className ? ` ${className}` : '')}>Paid by {getMethodDescription(method).toLowerCase()}</span>;
            case 'debit':
                return <span className={(className ? ` ${className}` : '')}>Paid by {getMethodDescription(method)}</span>;
            case 'etransfer':
                return <span className={(className ? ` ${className}` : '')}>Paid by {getMethodDescription(method)}</span>;
            case 'cheque':
                return <span className={(className ? ` ${className}` : '')}>Paid by {getMethodDescription(method).toLowerCase()}</span>;
            case 'visa':
                return <span className={(className ? ` ${className}` : '')}>Paid by {getMethodDescription(method)}</span>;
            case 'mastercard':
                return <span className={(className ? ` ${className}` : '')}>Paid by {getMethodDescription(method)}</span>;
            case 'amex':
                return <span className={(className ? ` ${className}` : '')}>Paid by {getMethodDescription(method)}</span>;
            case 'preauthorized':
                return <span className={(className ? ` ${className}` : '')}>{getMethodDescription(method)} payment</span>;
            case 'privateinsurance':
                return <span className={(className ? ` ${className}` : '')}>Paid by {getMethodDescription(method).toLowerCase()}</span>;
            case 'directdeposit':
                return <span className={(className ? ` ${className}` : '')}>{getMethodDescription(method)}</span>;
            case 'storecredit':
                return <span className={(className ? ` ${className}` : '')}>Paid by {getMethodDescription(method).toLowerCase()}</span>;
            default:
                break;
        }
    }

    return <span className={(className ? ` ${className}` : '')}>Paid</span>;
}

export const toRefundDescription = (method, className) => {
    if (method) {
        switch (method.toLowerCase()) {
            case 'cash':
                return <span className={(className ? ` ${className}` : '')}>Refunded in {getMethodDescription(method).toLowerCase()}</span>;
            case 'debit':
                return <span className={(className ? ` ${className}` : '')}>Refunded to {getMethodDescription(method)}</span>;
            case 'visa':
                return <span className={(className ? ` ${className}` : '')}>Refunded to {getMethodDescription(method)}</span>;
            case 'mastercard':
                return <span className={(className ? ` ${className}` : '')}>Refunded to {getMethodDescription(method)}</span>;
            case 'amex':
                return <span className={(className ? ` ${className}` : '')}>Refunded to {getMethodDescription(method)}</span>;
            case 'storecredit':
                return <span className={(className ? ` ${className}` : '')}>Refunded as {getMethodDescription(method).toLowerCase()}</span>;
            default:
                break;
        }
    }

    return <span className={(className ? ` ${className}` : '')}>Refunded</span>;
}

export const toFileSize = (bytes, si) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

    let u = -1;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);

    return bytes.toFixed(1) + ' ' + units[u];
}

export const formatPhone = (phone) => {
    if (!phone) return null;

    let result = phone.replace(/\D/g, '');

    if (result.length === 10) {
        result = `+1${result}`;
    }
    else if (result.length === 11 && result.startsWith('1')) {
        result = `+${result}`;
    }

    return result;
}

export const formatShortDate = (date, format) => {
    const mdate = isMoment(date) ? date.clone() : moment(date);
    return mdate.format((format ? format : `YYYY/MM/DD`));
}

export const formatDate = (date, format) => {
    const mdate = isMoment(date) ? date.clone() : moment(date);
    return mdate.format((format ? format : `MMMM Do, YYYY`));
}

export const formatDateWithOrWithoutYear = (date, compareToDate, format) => {
    const mdate = isMoment(date) ? date.clone() : moment(date);
    const mCompareDate = compareToDate = isNullOrUndefined(compareToDate) ? moment() : compareToDate;
    format = (format ? format : `MMMM Do, YYYY`);

    if (mdate.year() === mCompareDate.year()) {
        format = format.replace(/Y/g, '').trim().replace(/(^,)|(,$)/g, '');
    }

    return mdate.format(format);
}

export const formatFullDate = (date, compareToDate) => {
    compareToDate = isNullOrUndefined(compareToDate) ? moment() : compareToDate;
    const mdate = isMoment(date) ? date.clone() : moment(date);

    return mdate.format(`dddd, MMMM Do, YYYY`);
}

export const formatFullDateHtml = (date, compareToDate) => {
    compareToDate = isNullOrUndefined(compareToDate) ? moment() : compareToDate;

    const mdate = isMoment(date) ? date.clone() : moment(date);
    const ordinal = mdate.format('Do').replace(mdate.format('D'), '');

    return <>{mdate.format('dddd, MMMM D')}<sup>{ordinal}</sup>, {mdate.format('YYYY')}</>
}

export const formatFullDateWithOrWithoutYear = (date, compareToDate) => {
    compareToDate = isNullOrUndefined(compareToDate) ? moment() : compareToDate;

    const mdate = isMoment(date) ? date.clone() : moment(date);
    const mCompareDate = isMoment(compareToDate) ? compareToDate.clone() : moment(compareToDate);

    return mdate.format(`dddd, MMMM Do${mdate.year() === mCompareDate.year() ? '' : ', YYYY'}`);
}

export const formatFullDateWithOrWithoutYearHtml = (date, compareToDate, includeDayOfWeek = true) => {
    compareToDate = isNullOrUndefined(compareToDate) ? moment() : compareToDate;

    const mdate = isMoment(date) ? date.clone() : moment(date);
    const mCompareDate = isMoment(compareToDate) ? compareToDate.clone() : moment(compareToDate);
    const ordinal = mdate.format('Do').replace(mdate.format('D'), '');

    return <>{mdate.format(`${(includeDayOfWeek ? 'dddd, ' : '')}MMMM D`)}<sup>{ordinal}</sup>{mdate.year() !== mCompareDate.year() ? `, ${mdate.format('YYYY')}` : ''}</>
}

export const formatPercent = (value, defaultValue) => {
    const floatValue = parseFloat(value);
    if (defaultValue && (isNaN(floatValue) || floatValue === 0)) return defaultValue;

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'percent',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    return formatter.format(value);
}

export const formatCurrency = (value, defaultValue) => {
    const floatValue = parseFloat(value);
    if (defaultValue && (isNaN(floatValue) || floatValue === 0)) return defaultValue;

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return formatter.format(value);
}

export const parseCurrency = (value, defaultValue) => {
    if (!value) return defaultValue;

    const thousandSeparator = (1111).toLocaleString().replace(/1/g, '');
    const decimalSeparator = (1.1).toLocaleString().replace(/1/g, '');
    const floatValue = parseFloat(value.toString()
        .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
        .replace(new RegExp('\\' + decimalSeparator), '.')
        .replace(/[^\d.-]/g, '')
    );

    return isNaN(floatValue) ? (defaultValue ? defaultValue : null) : floatValue;
}

export const roundCurrency = (number) => {
    return Math.round((number + Number.EPSILON) * 100) / 100
}

export const isSameYear = (date, compareTo) => {
    if (!date || !isMoment(date)) return false;
    compareTo = compareTo && isMoment(compareTo) ? compareTo : moment();
    return date.format('YYYY') === compareTo.format('YYYY');
}

export const formatTime = (from, to, hideFromAmPm = false) => {
    const mFrom = isMoment(from) ? from.clone() : moment(from);
    const mTo = isMoment(to) ? to.clone() : moment(to);
    const mFromHour = mFrom.format('h');
    const mFromMin = mFrom.format('mm');
    const mFromAmPm = mFrom.format('a');
    const mToHour = mTo.format('h');
    const mToMin = mTo.format('mm');
    const mToAmPm = mTo.format('a');

    if (mFrom.format('h:mm a') === mTo.format('h:mm a')) {
        return `${mFromHour}${mFromMin !== '00' ? `:${mFromMin}` : ''}${!hideFromAmPm ? ` ${mFromAmPm}` : ''}`;
    } else {
        return `${mFromHour}${mFromMin !== '00' ? `:${mFromMin}` : ''}${!hideFromAmPm && mFromAmPm !== mToAmPm ? ` ${mFromAmPm}` : ''} - ${mToHour}${mToMin !== '00' ? `:${mToMin}` : ''} ${mToAmPm}`
    }
}

export const formatTimeFromToHtml = (from, to, hideFromAmPm = false) => {
    const mFrom = isMoment(from) ? from.clone() : moment(from);
    const mTo = isMoment(to) ? to.clone() : moment(to);
    const mFromHour = mFrom.format('h');
    const mFromMin = mFrom.format('mm');
    const mFromAmPm = mFrom.format('a');
    const mToHour = mTo.format('h');
    const mToMin = mTo.format('mm');
    const mToAmPm = mTo.format('a');

    if (mFrom.format('h:mm a') === mTo.format('h:mm a')) {
        return <span className='time-range text-nowrap'><time>{mFromHour}{mFromMin !== '00' ? `:${mFromMin}` : ''}{!hideFromAmPm ? <span className='ampm'>{mFromAmPm}</span> : null}</time></span>;
    } else {
        return <span className='time-range text-nowrap'><time>{mFromHour}{mFromMin !== '00' ? `:${mFromMin}` : ''}{!hideFromAmPm && mFromAmPm !== mToAmPm ? <span className='ampm'>{mFromAmPm}</span> : null}</time><span className='time-range-dash'>&minus;</span><time>{mToHour}{mToMin !== '00' ? `:${mToMin}` : ''}<span className='ampm'>{mToAmPm}</span></time></span>
    }
}

export const getDatePastOrFutureDayDescription = (date1, date2, overrides) => {
    const mDate1 = isMoment(date1) ? date1.clone() : moment(date1);
    const mDate2 = date2 ? (isMoment(date2) ? date2.clone() : moment(date2)) : moment();
    const defaults = {
        today: 'Today',
        yesterday: 'Yesterday',
        tomorrow: 'Tomorrow',
        overPrefix: 'Over',
        pastSuffix: 'old',
        futureSuffix: 'in the future',
    };
    const option = { ...defaults, ...overrides }

    if (mDate1.startOf('day').isSame(mDate2.startOf('day'))) {
        return option.today;
    } else {
        const years = mDate1.diff(mDate2, 'years');
        const months = mDate1.diff(mDate2, 'months');
        const weeks = mDate1.diff(mDate2, 'weeks');
        const days = mDate1.diff(mDate2, 'days');

        if (years !== 0) {
            if (years < 0) {
                return `${months % 12 !== 0 || weeks % 52 !== 0 ? option.overPrefix : ''} ${Math.abs(years)} year${Math.abs(years) !== 1 ? 's' : ''} ${option.pastSuffix}`.trim();
            }
            if (years > 0) {
                return `${months % 12 !== 0 || weeks % 52 !== 0 ? option.overPrefix : ''} ${Math.abs(years)} year${Math.abs(years) !== 1 ? 's' : ''} ${option.futureSuffix}`.trim();
            }
        }

        if (months !== 0) {
            if (months < 0) {
                return `${weeks % 4 !== 0 ? option.overPrefix : ''} ${Math.abs(months)} month${Math.abs(months) !== 1 ? 's' : ''} ${option.pastSuffix}`.trim();
            }
            if (months > 0) {
                return `${weeks % 4 !== 0 ? option.overPrefix : ''} ${Math.abs(months)} month${Math.abs(months) !== 1 ? 's' : ''} ${option.futureSuffix}`.trim();
            }
        }

        if (weeks !== 0) {
            if (weeks < 0) {
                return `${days % 7 !== 0 ? option.overPrefix : ''} ${Math.abs(weeks)} week${Math.abs(weeks) !== 1 ? 's' : ''} ${option.pastSuffix}`.trim();
            }
            if (weeks > 0) {
                return `${days % 7 !== 0 ? option.overPrefix : ''} ${Math.abs(weeks)} week${Math.abs(weeks) !== 1 ? 's' : ''} ${option.futureSuffix}`.trim();
            }
        }

        if (days !== 0) {
            if (days < 0) {
                if (days === -1) {
                    return option.yesterday;
                }
                else {
                    return `${Math.abs(days)} days ${option.pastSuffix}`.trim();
                }
            }
            if (days > 0) {
                if (days === 1) {
                    return option.tomorrow;
                }
                else {
                    return `${Math.abs(days)} days ${option.futureSuffix}`.trim();
                }
            }
        }
    }
}

export const getDatePastOrFutureWeekDescription = (date1, date2, overrides) => {
    const mDate1 = (isMoment(date1) ? date1.clone() : moment(date1)).startOf('week');
    const mDate2 = (date2 ? (isMoment(date2) ? date2.clone() : moment(date2)) : moment()).startOf('week');
    const defaults = {
        thisWeek: 'This week',
        lastWeek: 'Last week',
        nextWeek: 'Next week',
        overPrefix: 'Over',
        pastSuffix: 'old',
        futureSuffix: 'in the future',
    };
    const option = { ...defaults, ...overrides }

    if (mDate1.startOf('day').isSame(mDate2.startOf('day'))) {
        return option.thisWeek;
    } else {
        const years = mDate1.diff(mDate2, 'years');
        const months = mDate1.diff(mDate2, 'months');
        const weeks = mDate1.diff(mDate2, 'weeks');

        if (years !== 0) {
            if (years < 0) {
                return `${months % 12 !== 0 || weeks % 52 !== 0 ? option.overPrefix : ''} ${Math.abs(years)} year${Math.abs(years) !== 1 ? 's' : ''} ${option.pastSuffix}`.trim();
            }
            if (years > 0) {
                return `${months % 12 !== 0 || weeks % 52 !== 0 ? option.overPrefix : ''} ${Math.abs(years)} year${Math.abs(years) !== 1 ? 's' : ''} ${option.futureSuffix}`.trim();
            }
        }

        if (months !== 0) {
            if (months < 0) {
                return `${weeks % 4 !== 0 ? option.overPrefix : ''} ${Math.abs(months)} month${Math.abs(months) !== 1 ? 's' : ''} ${option.pastSuffix}`.trim();
            }
            if (months > 0) {
                return `${weeks % 4 !== 0 ? option.overPrefix : ''} ${Math.abs(months)} month${Math.abs(months) !== 1 ? 's' : ''} ${option.futureSuffix}`.trim();
            }
        }

        if (weeks !== 0) {
            if (weeks < 0) {
                if (weeks === -1) {
                    return option.lastWeek;
                }
                else {
                    return `${Math.abs(weeks)} weeks ${option.pastSuffix}`.trim();
                }
            }
            if (weeks > 0) {
                if (weeks === 1) {
                    return option.nextWeek;
                }
                else {
                    return `${Math.abs(weeks)} weeks ${option.futureSuffix}`.trim();
                }
            }
        }
    }
}

export const timeAgoPastFormatter = (value, unit, suffix, pastUnit = 'second', pastValue = 5, pastDisplay = 'just now', shortFormat = false) => {
    if (unit === pastUnit && value < pastValue && suffix === 'ago') return pastDisplay;

    const plural = value !== 1 ? 's' : '';

    return shortFormat ? `${value} ${getUnitShortFormat(`${unit}${plural}`)} ${suffix}`.trim() : `${value} ${unit}${plural} ${suffix}`.trim();
}

export const timeAgoFutureFormatter = (value, unit, suffix, futureUnit = 'second', futureValue = 5, futureDisplay = 'soon', shortFormat = false) => {
    if (unit === futureUnit && value < futureValue && suffix === 'from now') return futureDisplay;

    const plural = value !== 1 ? 's' : '';

    return shortFormat ? `${value} ${getUnitShortFormat(`${unit}${plural}`)} ${suffix}`.trim() : `${value} ${unit}${plural} ${suffix}`.trim();
}

export const timeAgoMinimumFormatter = (value, unit, suffix, pastUnit = 'second', pastValue = 5, pastDisplay = 'just now', futureUnit = 'second', futureValue = 5, futureDisplay = 'just now', shortFormat = false) => {
    if (suffix === 'ago') {
        return timeAgoPastFormatter(value, unit, suffix, pastUnit, pastValue, pastDisplay, shortFormat);
    }
    if (suffix === 'from now') {
        return timeAgoFutureFormatter(value, unit, suffix, futureUnit, futureValue, futureDisplay, shortFormat);
    }

    const plural = value !== 1 ? 's' : '';

    return `${value} ${unit}${plural} ${suffix}`.trim();
}

export const timeAgoDayFormatter = (value, unit, suffix) => {
    switch (unit) {
        case 'second':
            if (suffix === 'ago') {
                if (value > 86400 && value <= 172800) {
                    return 'Yesterday';
                }
                if (value > 0 && value <= 86400) {
                    return 'Today'
                }
            }
            if (suffix === 'from now') {
                return 'Tomorrow';
            }
            break;

        case 'minute':
            if (suffix === 'ago') {
                if (value > 1440 && value <= 2880) {
                    return 'Yesterday';
                }
                if (value > 0 && value <= 1440) {
                    return 'Today';
                }
            }
            if (suffix === 'from now') {
                return 'Tomorrow';
            }
            break;

        case 'hour':
            if (suffix === 'ago') {
                if (value > 24 && value <= 48) {
                    return 'Yesterday';
                }
                if (value > 0 && value <= 24) {
                    return 'Today';
                }
            }
            if (suffix === 'from now') {
                return 'Tomorrow';
            }
            break;

        case 'day':
            if (suffix === 'ago') {
                if (value > 0 && value <= 1) {
                    return 'Yesterday';
                }
            }
            break;

        default:
            break;
    }

    if (suffix === 'from now') {
        if (unit === 'day') {
            if (value === 7) {
                return `1 week later`.trim();
            }
        }
        return `${value} ${unit}${value !== 1 ? 's' : ''} later`.trim();
    }

    if (suffix === 'ago') {
        return `${value} ${unit}${value !== 1 ? 's' : ''} ${suffix}`.trim();
    }
}

export const timeAgoWeekFormatter = (value, unit, suffix) => {
    switch (unit) {
        case 'second':
        case 'minute':
        case 'hour':
            return 'This week';

        case 'day':

            if (suffix === 'ago') {
                if (value > 7 && value <= 14) {
                    return '1 week ago';
                }
                if (value > 0 && value <= 7) {
                    return 'This week';
                }
            }
            if (suffix === 'from now') {
                if (value > 0 && value <= 7) {
                    return '1 week later';
                }
            }
            break;

        default:
            break;
    }

    if (suffix === 'from now') {
        return `${value} ${unit}${value !== 1 ? 's' : ''} later`.trim();
    }

    if (suffix === 'ago') {
        return `${value} ${unit}${value !== 1 ? 's' : ''} ${suffix}`.trim();
    }
}

export const similarity = (string1, string2) => {
    let longer = string1;
    let shorter = string2;
    if (string1.length < string2.length) {
        longer = string2;
        shorter = string1;
    }
    let longerLength = longer.length;
    if (longerLength === 0) {
        return 1.0;
    }
    return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
}

export const validateForm = (root, showMessage = true) => {
    let isFormValid = true;

    if (root) {
        const elements = Array.from(root.querySelectorAll('[class*="validate-"]')).filter(e => e.offsetParent !== null);
        for (let i = 0; i < elements.length; i++) {
            let isElementValid = true;
            const textboxes = Array.from(elements[i].querySelectorAll('input[type="text"], input[type="number"], input[type="email"], input[type="password"]')).filter(e => e.offsetParent !== null);
            const dropdowns = Array.from(elements[i].querySelectorAll('select')).filter(e => e.offsetParent !== null);
            const checkboxes = Array.from(elements[i].querySelectorAll('input[type="checkbox"]')).filter(e => e.offsetParent !== null);
            const radioButtons = Array.from(elements[i].querySelectorAll('input[type="radio"]')).filter(e => e.offsetParent !== null);
            const richTextboxes = Array.from(elements[i].querySelectorAll('div.ql-editor')).filter(e => e.offsetParent !== null);
            const comboBoxes = Array.from(elements[i].querySelectorAll('div.rw-dropdown-list')).filter(e => e.offsetParent !== null);
            const multiSelectTextboxes = Array.from(elements[i].querySelectorAll('div.rw-multiselect')).filter(e => e.offsetParent !== null);

            if (Array.from(elements[i].classList).some(c => c === 'validate-phone')) {
                if (isElementValid && Array.from(elements[i].querySelectorAll('[data-validator-phone="false"]')).length > 0) {
                    isElementValid = false;
                }
                else if (isElementValid && textboxes && textboxes.length > 0 && textboxes.some(t => t.value && !regexTester.phone.test(t.value))) {
                    isElementValid = false;
                }
            }

            if (Array.from(elements[i].classList).some(c => c === 'validate-email')) {
                if (isElementValid && Array.from(elements[i].querySelectorAll('[data-validator-email="false"]')).length > 0) {
                    isElementValid = false;
                }
                else if (isElementValid && textboxes && textboxes.length > 0 && textboxes.some(t => t.value && !regexTester.email.test(t.value))) {
                    isElementValid = false;
                }
            }

            if (Array.from(elements[i].classList).some(c => c === 'validate-date')) {
                if (isElementValid && Array.from(elements[i].querySelectorAll('[data-validator-date="false"]')).length > 0) {
                    isElementValid = false;
                }
                else if (isElementValid && textboxes && textboxes.length > 0 && textboxes.some(t => t.value && !regexTester.date.test(t.value))) {
                    isElementValid = false;
                }
            }

            if (Array.from(elements[i].classList).some(c => c === 'validate-healthcard')) {
                if (isElementValid && Array.from(elements[i].querySelectorAll('[data-validator-healthcard="false"]')).length > 0) {
                    isElementValid = false;
                }
                else if (isElementValid && textboxes && textboxes.length > 0 && textboxes.some(t => t.value && !(regexTester.ohip.test(t.value.replace(/-/g, '')) || regexTester.ohipOld.test(t.value.replace(/-/g, ''))))) {
                    isElementValid = false;
                }
            }

            if (Array.from(elements[i].classList).some(c => c === 'validate-healthcard-code')) {
                if (isElementValid && Array.from(elements[i].querySelectorAll('[data-validator-healthcard-code="false"]')).length > 0) {
                    isElementValid = false;
                }
                else if (isElementValid && textboxes && textboxes.length > 0 && textboxes.some(t => t.value && !regexTester.ohipCode.test(t.value))) {
                    isElementValid = false;
                }
            }

            if (Array.from(elements[i].classList).some(c => c === 'validate-required')) {
                if (isElementValid && Array.from(elements[i].querySelectorAll('[data-validator-required="false"]')).length > 0) {
                    isElementValid = false;
                }
                else if (isElementValid && multiSelectTextboxes && multiSelectTextboxes.length > 0 && !multiSelectTextboxes.some(m => m.querySelectorAll('ul.rw-multiselect-taglist').length > 0)) {
                    isElementValid = false;
                }
                else if (isElementValid && comboBoxes && comboBoxes.length > 0 && (!comboBoxes.some(m => m.querySelectorAll('.rw-dropdown-list-autofill[value="[object Object]"]')) || comboBoxes.some(m => m.querySelectorAll('.rw-dropdown-list-autofill[value=""]').length > 0))) {
                    isElementValid = false;
                }
                else if (isElementValid && richTextboxes && richTextboxes.length > 0 && richTextboxes.some(r => r.classList.contains('ql-blank'))) {
                    isElementValid = false;
                }
                else if (isElementValid && textboxes && textboxes.length > 0 && textboxes.some(t => !t.closest('.dx-htmleditor') && !t.value)) {
                    isElementValid = false;
                }
                else if (isElementValid && dropdowns && dropdowns.length > 0 && dropdowns.some(t => !t.value)) {
                    isElementValid = false;
                }
                else if (isElementValid && checkboxes && checkboxes.length > 0 && !checkboxes.some(c => !!c.checked)) {
                    isElementValid = false;
                }
                else if (isElementValid && radioButtons && radioButtons.length > 0 && !radioButtons.some(r => !!r.checked)) {
                    isElementValid = false;
                }
            }

            if (Array.from(elements[i].classList).some(c => c === 'validate-empty')) {
                if (isElementValid && Array.from(elements[i].querySelectorAll('[data-validator-empty="false"]')).length > 0) {
                    isElementValid = false;
                }
                else if (isElementValid && multiSelectTextboxes && multiSelectTextboxes.length > 0 && !multiSelectTextboxes.some(m => m.querySelectorAll('ul.rw-multiselect-taglist').length === 0)) {
                    isElementValid = false;
                }
                else if (isElementValid && richTextboxes && richTextboxes.length > 0 && richTextboxes.some(r => !r.classList.contains('ql-blank'))) {
                    isElementValid = false;
                }
                else if (isElementValid && textboxes && textboxes.length > 0 && textboxes.some(t => !!t.value)) {
                    isElementValid = false;
                }
                else if (isElementValid && dropdowns && dropdowns.length > 0 && dropdowns.some(t => !!t.value)) {
                    isElementValid = false;
                }
                else if (isElementValid && checkboxes && checkboxes.length > 0 && checkboxes.some(c => !!c.checked)) {
                    isElementValid = false;
                }
                else if (isElementValid && radioButtons && radioButtons.length > 0 && radioButtons.some(r => !!r.checked)) {
                    isElementValid = false;
                }
            }

            if (Array.from(elements[i].classList).some(c => c === 'validate-regex')) {
                if (isElementValid && richTextboxes && richTextboxes.length > 0 && richTextboxes.some(r => !r.classList.contains('ql-blank'))) {
                    isElementValid = false;
                }
                else if (isElementValid && textboxes && textboxes.length > 0 && textboxes.some(t => t.value && !(new RegExp(t.dataset.validateRegex.replace(/^\//, '')).test(t.value)))) {
                    isElementValid = false;
                }
            }

            if (!isElementValid) {
                elements[i].classList.add('has-error');
            } else {
                elements[i].classList.remove('has-error');
            }

            isFormValid = isFormValid && isElementValid;
        }
    }

    if (!isFormValid && showMessage) {
        toast.error(() => ErrorMessages.GENERIC_FORM_ERROR_HTML, ErrorMessages.toastOption());
    }

    return isFormValid;
}

export const convertStylesToJS = (style) => {
    if (!style) return null;

    const keyValues = style.split(';').filter(s => s && s.length > 0).map(obj => {
        const parts = obj.split(':');
        const key = parts[0].trim().replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
        const value = parts[1].trim();
        return { [key]: value };
    });

    const styles = Object.assign({}, ...keyValues);
    return styles;
}

export const convertImageUrlToBase64 = (key, url) => {
    return new Promise((resolve, reject) => {
        const image = new Image();

        image.crossOrigin = 'Anonymous';
        image.onload = () => {
            let canvas = document.createElement('CANVAS');
            let context = canvas.getContext('2d');
            let format;
            let dataURL;

            if (url.indexOf('.jpg') > -1) {
                format = 'image/jpg';
            }
            else if (url.indexOf('.gif') > -1) {
                format = 'image/gif';
            }
            else if (url.indexOf('.png') > -1) {
                format = 'image/png';
            }
            else if (url.indexOf('.bmp') > -1) {
                format = 'image/bmp';
            }
            else {
                reject('Invalid image format');
            }

            canvas.height = image.height;
            canvas.width = image.width;

            context.drawImage(image, 0, 0);
            dataURL = canvas.toDataURL(format);

            resolve({ key: key, base64: dataURL });
            canvas = null;
            context = null;
        };
        image.onerror = () => {
            reject(`Invalid image url - ${url}`);
        }
        image.src = url;
    })
}

export const convertBase64ToBlob = (base64, contentType = '', sliceSize = 512) => {
    base64 = base64.indexOf(',') >= 0 ? base64.split(',')[1] : base64;

    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

export const getHighestZIndex = () => {
    const zIndex = Math.max.apply(Math,
        Array.prototype.slice.call(document.querySelectorAll('*'))
            .map(e => { return window.getComputedStyle(e) })
            .filter(e => e.display !== 'none' && e.zIndex !== 'auto')
            .map(e => { return parseInt(e.zIndex, 10) })
            .filter(z => z !== 2147483647).flat()); // 2147483647 is the max number for z-index.  It is used by DevExtreme for their Click and Drag overlay.
    const newZIndex = (zIndex === Infinity || zIndex === -Infinity ? 0 : (zIndex + 10));

    baseZIndex(newZIndex + 10);  // DevExtreme -> This is to set all DevExtreme overlay.

    return newZIndex;
}

export const urlFriendly = (url) => {
    return url.toLowerCase().replace(/[^a-zA-Z0-9]/g, '-').replace(/--+/g, '-').replace(/^[-]+|[-]+$/g, "");
}

export const plainTextToHtml = (text) => {
    return text.replace(/(?:\r\n|\r|\n)/g, '<br />');
}

export const canvasToRasterImage = (canvas, type, backgroundColor) => {
    let context = canvas.getContext("2d")
    //cache height and width		
    let w = canvas.width;
    let h = canvas.height;
    let data;

    if (backgroundColor) {
        //get the current ImageData for the canvas.
        data = context.getImageData(0, 0, w, h);

        //store the current globalCompositeOperation
        var compositeOperation = context.globalCompositeOperation;

        //set to draw behind current content
        context.globalCompositeOperation = "destination-over";

        //set background color
        context.fillStyle = backgroundColor;

        //draw background / rect on entire canvas
        context.fillRect(0, 0, w, h);
    }

    //get the image data from the canvas
    var imageData = canvas.toDataURL(`image/${type}`);

    if (backgroundColor) {
        //clear the canvas
        context.clearRect(0, 0, w, h);

        //restore it with original / cached ImageData
        context.putImageData(data, 0, 0);

        //reset the globalCompositeOperation to what it was
        context.globalCompositeOperation = compositeOperation;
    }

    //return the Base64 encoded data url string
    return imageData;
}

export const stripHtml = (text, replaceWith = '&nbsp;') => {
    if (regexTester.html.test(text)) {
        const div = document.createElement("div");

        text = text.replace(/<p><br\s*[/]?><\/p>/gi, replaceWith);
        text = text.replace(/<p><\/p>/gi, replaceWith);
        text = text.replace(/<br\s*[/]?>/gi, replaceWith);
        text = text.replace(/<\/p[^>]*>/gi, `</p>${replaceWith}`)
        div.innerHTML = text;

        return div.innerText;
    }

    return text ? text : '';
}

export const htmlEncode = (text) => {
    const textArea = document.createElement("textarea");
    textArea.innerText = text;
    return textArea.innerHTML.split("<br>").join("\n");
}

export const replaceAHref = (html, replaceWith = 'javascript:void(0);') => { // eslint-disable-line
    if (!html) return;
    return html.replace(/href="(.+?)"/g, () => { return `href="${replaceWith}"` });
}

export const getTextAreaRows = (value) => {
    const numberOfReturns = value ? (value.match(/\n/g) || []).length : 0;
    return (numberOfReturns + 1);
}

export const getEmailDomainFromAddress = (emailAddress) => {
    if (!emailAddress) return null;

    const regex = /@([A-Za-z0-9-]+\.[A-Za-z0-9-.]+)/;
    const match = emailAddress.match(regex);

    return match ? match[1].toLowerCase() : null;
}

export const toTitleCase = (str) => {
    if (!str) return null;

    return str.toLowerCase().replace(/\b\w/g, (match) => {
        return match.toUpperCase();
    });
}

export const regexTester = {
    // eslint-disable-next-line
    phone: /(^[+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/,
    // eslint-disable-next-line
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    // eslint-disable-next-line    
    name: /^(?:(?![×Þß÷þø])[-'a-zÀ-ÿ ])+$/i,
    // eslint-disable-next-line    
    date: /^\d{4}-\d{1,2}-\d{1,2}$/,
    // eslint-disable-next-line    
    postal: /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
    // eslint-disable-next-line    
    ohipOld: /^[1-9]\d{9}$/,
    // eslint-disable-next-line    
    ohip: /^[1-9]\d{9}[a-zA-Z]{2}$/,
    // eslint-disable-next-line    
    ohipNoCode: /^[1-9]\d{9}$/,
    // eslint-disable-next-line    
    ohipCode: /^[a-zA-Z]{2}$/,
    // eslint-disable-next-line    
    currency: /^\$?[0-9]+\.?[0-9]?[0-9]?$/,
    // eslint-disable-next-line    
    discountRate: /^([1-9][0-9]?|^100)%$/,
    // eslint-disable-next-line    
    html: /<\/?[a-z][\s\S]*>/i,
    // eslint-disable-next-line    
    timeSlot: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/i,
    // eslint-disable-next-line    
    boolean: /^(true|false)$/i,
}

export const regexMatch = {
    // eslint-disable-next-line    
    streetNumberAndName: /^(.+)\s(\S+)$/i,
}

const editDistance = (string1, string2) => {
    string1 = string1.toLowerCase();
    string2 = string2.toLowerCase();

    var costs = [];
    for (var i = 0; i <= string1.length; i++) {
        var lastValue = i;
        for (var j = 0; j <= string2.length; j++) {
            if (i === 0)
                costs[j] = j;
            else {
                if (j > 0) {
                    var newValue = costs[j - 1];
                    if (string1.charAt(i - 1) !== string2.charAt(j - 1))
                        newValue = Math.min(Math.min(newValue, lastValue),
                            costs[j]) + 1;
                    costs[j - 1] = lastValue;
                    lastValue = newValue;
                }
            }
        }
        if (i > 0)
            costs[string2.length] = lastValue;
    }
    return costs[string2.length];
}

const getUnitShortFormat = (unit) => {
    if (!unit) return '';

    switch (unit.toLowerCase()) {
        case 'year':
        case 'years':
            return 'yr';

        case 'month':
        case 'months':
            return 'mo.';

        case 'week':
        case 'weeks':
            return 'wk';

        case 'day':
            return 'day';

        case 'days':
            return 'days';

        case 'hour':
        case 'hours':
            return 'hr';

        case 'minute':
        case 'minutes':
            return 'min';

        case 'second':
        case 'seconds':
            return 'sec';

        default:
            return unit;
    }
}

export const parseReformatTime = (str) => {
    // this function is to parse and reformat (if necessary) user input time string to make it conform to hh:mm am|pm format.
    const regex1 = /^(\d+)\s*(am|pm)/i; // e.g. 9am => 9:00 am
    if (regex1.test(str)) {
        str = str.replace(regex1, "$1:00 $2");
    }
    const regex2 = /^(\d+:\d+)\s*(am|pm)/i; // e.g. 9:00am => 9:00 am
    if (regex2.test(str)) {
        str = str.replace(regex2, "$1 $2");
    }

    // Check if the input matches the hh:mm a format
    const regex = /^([0-1]?\d):([0-5]\d) (am|pm)$/i;
    const match = regex.exec(str);
    if (match) {
        // Create a Date object with the current date and the input time
        const date = new Date();
        let hour = parseInt(match[1]);
        const minute = parseInt(match[2]);
        const period = match[3];
        // Convert 12-hour format to 24-hour format
        if (hour === 12) {
            hour = period.toLowerCase() === "am" ? 0 : 12;
        } else if (period.toLowerCase() === "pm") {
            hour += 12;
        }
        date.setHours(hour, minute);
        return date;
    } else {
        // Return null if the input is invalid
        return null;
    }
};
