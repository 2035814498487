export const OnboardingStatusType = (() => {
    const _enum = Object.freeze({
        Initial: 0,
        UpdatedEMRVendor: 1,
        RetrievalAuthorized: 2,
        DatabaseParsed: 4
    });

    return _enum;
})();
