import { makeAutoObservable } from 'mobx';
import api from '../api';
import * as fn from '../utilities/_functions';
import { CLINIC_ID } from '../constants/storageKeys';

class ClinicStore {
    data = [];
    types = [];
    options = [];
    hasUnsavedChanges = false;
    isReady = false;
    isSaving = false;
    status = null;
    cancelClinic = null;

    constructor() {
        makeAutoObservable(this);
    }
    
    setStatus(status) {
        this.status = status;
    }
    
    initialize = () => {
        this.clear();
        this.isReady = true;
    }
    refresh = async (id) => {
        this.isLoading = true;
        if (!id) {
            id = window.localStorage.getItem(CLINIC_ID);
        }
        let { data } = await api.Clinics.get(id, (c) => { this.cancelClinic = c });
        this.data = data;
        this.setStatus(data.onboardingStatus); 
        this.isLoading = false;
    }

    update = async (formData) => {
        try {
            this.isSaving = true;
            const resp = await api.Clinics.update(formData, (c) => { this.cancelClinic = c });
            this.data = resp.data;
            //this.setStatus(this.status === 0 ? 2 : 0);
            return this.data;
        }
        finally {
            this.isSaving = false;
        }
    }

    updatePlatform = async (formData) => {
        try {
            this.isSaving = true;
            await api.Clinics.updatePlatform(formData, (c) => { this.cancelClinic = c });
            return this.data;
        }
        finally {
            this.isSaving = false;
        }
    }

    updateLaunchDate = async (formData) => {
        try {
            this.isSaving = true;
            await api.Clinics.updateLaunchDate(formData, (c) => { this.cancelClinic = c });
            return this.data;
        }
        finally {
            this.isSaving = false;
        }
    }

    updateBackupUrls = async (formData) => {
        try {
            this.isSaving = true;
            await api.Clinics.updateBackupUrls(formData, (c) => { this.cancelClinic = c });
            return this.data;
        }
        finally {
            this.isSaving = false;
        }
    }

    authorizeRetrieval = async (clinicId) => {
        try {
            this.isSaving = true;
            await api.Clinics.authorizeRetrieval({ clinicId }, (c) => { this.cancelClinic = c });
            return this.data;
        }
        finally {
            this.isSaving = false;
        }
    }

    clear = () => {
        this.data = [];
        this.hasUnsavedChanges = false;
        this.isReady = false;
        this.isSaving = false;

        if (fn.isFunction(this.cancelClinic)) {
            this.cancelClinic();
            this.cancelClinic = null;   
        }
    }
}

export const clinicStore = new ClinicStore();