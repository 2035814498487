import React, { } from 'react';

export default function WinkInfo() {
    return <div>
        <p><strong>What to expect with an Wink migration?</strong></p>
        <ul>
            <li>Launch window: 1-2 weeks
                <ul className='text-danger'>
                    <li><strong>Existing data WILL NOT be available at launch.</strong></li>
                    <li><strong>Data migration will require 1-2 weeks after receiving WINK backup data.</strong></li>
                </ul>
            </li>
            <li>Equipment integration (if compatible) can be scheduled after data migration.</li>
            <li>Includes</li>
            <ul>
                <li>Appointments</li>
                <li>Exam (Charting)</li>
                <li>Prescriptions</li>
                <li>Invoices / Payment Receipts</li>
                <li>Spectacle Orders</li>
                <li>Contact Orders</li>
                <li>Referral Appointments
                    <ul>
                        <li><strong>Referral Letter not included (see below)</strong></li>
                    </ul>
                </li>
                <li>Notes</li>
            </ul>
            <li className='text-danger'>NOT includes
                <ul className='text-danger'>
                    <li>Attachments (not provided by WINK)</li>
                    <li>Referral Letters</li>
                    <li>Inventory / Products
                        <ul><li>Excel / Google Sheets import tool will be provided.</li></ul>
                    </li>
                    <li>Any outstanding OHIP billing claims</li>
                </ul>
            </li>
        </ul>
    </div>
}

