import React, { useRef, useEffect, useState } from 'react';
import { Form, GroupItem, SimpleItem, Item, Label, RequiredRule, EmailRule, PatternRule, ButtonItem } from 'devextreme-react/form';
import { LoadIndicator, SelectBox } from 'devextreme-react';
import { observer, inject } from 'mobx-react';
import ODOfficeInfo from './migration/ODOfficeInfo';
import IFileInfo from './migration/IFileInfo';
import WinkInfo from './migration/WinkInfo';
import RevolutionEHRInfo from './migration/RevolutionEHRInfo';
import NoMigrationInfo from './migration/NoMigrationInfo';
import NavButtons from '../components/common/NavButtons';


const DataMigrationPage = inject('clinicStore')(observer(({ clinicStore, clinicId }) => {
    const isMounted = useRef(true);
    const [ready, setReady] = useState(false);
    const [platform, setPlatform] = useState(null);

    const platformOptions = [
        { id: 'ODOffice', name: 'ODOffice' },
        { id: 'IFILE', name: 'IFILE' },
        { id: 'Wink', name: 'Wink' },
        { id: 'RevolutionEHR', name: 'RevolutionEHR' },
        { id: 'None', name: '(None) - New office / Paper based)' }
    ];

    useEffect(() => {
        clinicStore.refresh(clinicId).then(data => {
            setPlatform(clinicStore.data.emrVendor);
            setReady(true);
        });

        return () => {
            isMounted.current = false;
        }
    }, []);  // eslint-disable-line

    const handlePlatformChange = (e) => {
        clinicStore.updatePlatform({ clinicId: clinicId, vendor: e.value })
        setPlatform(e.value);
    }

    const renderMigrationInfo = () => {
        switch (platform) {
            case 'ODOffice':
                return <ODOfficeInfo />;
            case 'IFILE':
                return <IFileInfo />;
            case 'Wink':
                return <WinkInfo />;
            case 'RevolutionEHR':
                return <RevolutionEHRInfo />;
            case 'None':
                return <NoMigrationInfo />;
            default:
                return <></>;
        }
    }
    return (
        <>
            <div className='p-4 w-50' >
                <h3>Data Migration</h3><p></p>
                {
                    ready ?
                        <>
                            <div className='d-flex align-items-center justify-content-start mb-3 gap-5'>
                                <div>What is your current platform? </div>
                                <SelectBox
                                    width={300}
                                    dataSource={platformOptions}
                                    displayExpr="name"
                                    valueExpr="id"
                                    value={platform}
                                    onValueChanged={handlePlatformChange}
                                />
                            </div>
                            {
                                renderMigrationInfo()
                            }
                            <NavButtons currentRoute='/data-migration' />
                        </> : <LoadIndicator height={40} width={40} />
                }
            </div>
        </>
    );
}));

export default DataMigrationPage;