import React, { } from 'react';

export default function IFileInfo() {
    return <div>
        <p><strong>IFile: What to expect with an iFile migration?</strong></p>
        <ul>
            <li>Launch window: 3-5 weeks
                <ul>
                    <li>Existing data will be available at launch.</li>
                </ul>
            </li>
            <li>Equipment integration (if compatible) can be scheduled after launch.</li>
            <li>Includes
                <ul>
                    <li>Appointments</li>
                    <li>Exam (Charting)</li>
                    <li>Prescriptions</li>
                    <li>Invoices / Payment Receipts</li>
                    <li>Spectacle Orders</li>
                    <li>Contact Orders</li>
                    <li>Referral Appointments
                        <ul><li>Referral Letter not included (see below)</li></ul>
                    </li>
                    <li>Notes / Attachments</li>
                </ul>
            </li>
            <li className='text-danger'>NOT includes
                <ul className='text-danger'>
                    <li>Referral Letters</li>
                    <li>Inventory / Products
                        <ul>
                            <li>Excel / Google Sheets import tool will be provided</li>
                        </ul>
                    </li>
                    <li>Any outstanding OHIP billing claims</li>
                </ul>
            </li>
        </ul>
    </div>
}

