import React from 'react';

function Header() {
  return (
    <header className="text-white d-flex justify-content-left align-items-center" style={{ height: '70px', backgroundColor: 'lightseagreen' }}>
      <div className="p-4 display-6">Genki Onboarding Assist</div>
    </header>
  );
}

export default Header;