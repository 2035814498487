import { makeAutoObservable } from 'mobx';
import api from '../api';
import * as fn from '../utilities/_functions';

export class UserStore {
    data = [];
    types = [];
    options = [];
    isReady = false;
    isSaving = false;
    cancelUser = null;

    constructor() {
        makeAutoObservable(this);
    }

    initialize = () => {
        this.clear();
        this.isReady = true;
    }

    refresh = async () => {
        this.isLoading = true;
        let { data } = await api.Users.all((c) => { this.cancelUser = c });
        this.data = data;
        this.isLoading = false;
    }

    add = async (formData) => {
        try {
            let { data } = await api.Users.add(formData, (c) => { this.cancelUser = c });
            return data;
        }
        finally {
            this.isSaving = false;
        }
    }

    update = async (formData) => {
        try {
            await api.Users.update(formData, (c) => { this.cancelUser = c });
            return this.data;
        }
        finally {
            this.isSaving = false;
        }
    }

    delete = async (id) => {
        try {
            await api.Users.delete(id, (c) => { this.cancelUser = c });
            return this.data;
        }
        finally {
            this.isSaving = false;
        }
    }

    clear = () => {
        this.data = [];
        this.isReady = false;
        this.isSaving = false;

        if (fn.isFunction(this.cancelUser)) {
            this.cancelUser();
            this.cancelUser = null;
        }
    }
}

export const userStore = new UserStore();
