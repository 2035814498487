import React, { useContext, useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { OnboardingStatusType } from '../../constants/common';
import { useNavigate } from 'react-router-dom';
import { set } from 'mobx';

// Navigation route sequence
const routeSequence = [
  '/clinic',
  '/data-migration',
  '/upload',
  '/services',
  '/users',
  '/launch-date'
];


const NavButtons = inject('clinicStore')(observer(({ clinicStore, currentRoute, onCustomNext }) => {
  const navigate = useNavigate();
  const [hasNext, setHasNext] = useState(false);
  const currentIndex = routeSequence.indexOf(currentRoute);
  const hasPrevious = currentIndex > 0;

  const handlePrevious = () => {
    if (hasPrevious) navigate(routeSequence[currentIndex - 1]);
  };

  const handleNext = () => {
    if (onCustomNext) {
      onCustomNext();
    } else if (hasNext) {
      navigate(routeSequence[currentIndex + 1]);
    }
  };

  const checkIfNextButtonShouldDisplay = () => {
    if (currentIndex >= routeSequence.length - 1) { // last page, no next button
      setHasNext(false);
      return;
    }

    if (clinicStore.data.retrievalAuthorizedDateUtc != null && (clinicStore.status & OnboardingStatusType.DatabaseParsed) === 0 && currentRoute === '/upload') {
      // customer has submitted db URL but we haven't parsed the db data yet
      setHasNext(false);
      return;
    }

    setHasNext(true);
  }

  const loadData = async () => {
    await clinicStore.refresh();
    checkIfNextButtonShouldDisplay();
  }

  useEffect(() => {
    if (clinicStore.status == null) {
      loadData();
    }
    else {
      checkIfNextButtonShouldDisplay();
    }
  }, [clinicStore.data]);

  return (
    <div className="d-flex justify-content-start gap-4">
      {hasPrevious && (
        <button className="btn btn-warning" style={{width: "160px"}} onClick={handlePrevious}>
          Previous
        </button>
      )}
      {hasNext && (
        <button className="btn btn-warning" style={{width: "160px"}} onClick={handleNext}>
          Next
        </button>
      )}
    </div>
  );
}));

export default NavButtons;