import { makeAutoObservable } from 'mobx';
import api from '../api';
import * as fn from '../utilities/_functions';
import { CLINIC_ID } from '../constants/storageKeys';

class ServiceStore {
    publicData = [];
    privateData = [];
    types = [];
    options = [];
    isReady = false;
    isSaving = false;
    cancelService = null;

    constructor() {
        makeAutoObservable(this);
    }

    initialize = () => {
        this.clear();
        this.isReady = true;
    }

    refresh = async (clinicId) => {
        this.isLoading = true;
        {
            let { data } = await api.Services.getPublic(clinicId, (c) => { this.cancelService = c });
            this.publicData = data;
        }
        {
            let { data } = await api.Services.getPrivate(clinicId, (c) => { this.cancelService = c });
            this.privateData = data;
        }
        this.isLoading = false;
    }

    add = async (formData) => {
        try {
            let { data } = await api.Services.add(formData, (c) => { this.cancelService = c });
            return data;
        }
        finally {
            this.isSaving = false;
        }
    }

    update = async (formData) => {
        try {
            await api.Services.update(formData, (c) => { this.cancelService = c });
            return this.publicData;
        }
        finally {
            this.isSaving = false;
        }
    }

    delete = async (id) => {
        try {
            await api.Services.delete(id, (c) => { this.cancelService = c });
            return this.publicData;
        }
        finally {
            this.isSaving = false;
        }
    }

    clear = () => {
        this.publicData = [];
        this.privateData = [];
        this.isReady = false;
        this.isSaving = false;

        if (fn.isFunction(this.cancelService)) {
            this.cancelService();
            this.cancelService = null;
        }
    }
}

export const serviceStore = new ServiceStore();
